import { gql } from '@apollo/client';

export const listMonitorVaultsQuery = gql`
  query ListMonitorVaults($limit: Int!, $nextToken: String) {
    listMonitorVaults(limit: $limit, nextToken: $nextToken) {
      items {
        name
        positions {
          currentTick
          limitLower
          limitUpper
          barsInsideLimit
          barsInsideBase
          baseLower
          baseUpper
        }
        needRebalance
        address
        displayName
        memberTokenRatio
        numAlerts
        numWarnings
        baseTokenValue
        pendingDeposits
        pendingDepositsRatio
        warnings {
          name
          value
        }
        vaultStrength
        tvl
        sevenDaysChange
        scarceTokenValue
        scarceTokenMarketCap
        vaultIRR
        vaultIrrAllTx
        irrForTimePeriod
        isHodlVault
        isInverted
        needRebalanceFrom
        lastRebalance
        rebalanceTicks
        maxDeposit
        alerts {
          name
          value
        }
        supportIrrTokenAmount {
          irrPercent
          amount
        }
        vaultMetrics {
          timeInterval
          feeApr
          lpApr
          lpPriceChange
          avgDTR
        }
      }
      nextToken
    }
  }
`;
