import { gql } from '@apollo/client';

// Define variable types for the query
export interface ListFarmsQueryVariables {
  limit: number;
  nextToken?: string | null;
}

// Modified query to support pagination
export const listFarmsQuery = gql`
  query listFarms($limit: Int!, $nextToken: String) {
    listFarms(limit: $limit, nextToken: $nextToken) {
      items {
        poolId
        farmPoolId
        farmName
        searchName
        shortLpName
        lpName
        lpAddress
        displayName
        exchange
        isExternal
        isGeneric
        isIchiPool
        isMigrating
        isRetired
        isUpcoming
        isDeposit
        isPosition
        lpPrice
        tvl
        farmTVL
        totalPoolLP
        weeklyAPY
        monthlyAPY
        yearlyAPY
        dailyAPY
        futureAPY
        launchDate
        chainId
        tokens {
          name
          displayName
          address
          decimals
          reserve
          price
          isBaseToken
        }
        farm {
          farmAddress
          farmId
          farmRewardTokenAddress
          farmRewardTokenDecimals
          farmRewardTokenName
        }
        extras {
          externalText
          externalUrl
          externalButton
          tradeUrl
        }
      }
      nextToken
    }
  }
`;
