import { useQuery } from '@apollo/client';
import { useEffect, useState, useRef, useCallback } from 'react';
import { MonitorVaults } from 'common/models/monitorVaults';
import { listMonitorVaultsQuery } from 'graphql/queries/listMonitorVaults.query';

const PAGE_SIZE = 500; // Adjust based on your needs
const AUTO_REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes in milliseconds

export const usePaginatedMonitorVaults = (onlyNeedRebalance: boolean = false) => {
  const [allVaults, setAllVaults] = useState<MonitorVaults[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const refreshIntervalRef = useRef<NodeJS.Timeout | null>(null);
  
  // Skip the initial automatic query - we'll handle it manually
  const { 
    loading: initialLoading, 
    error: initialError, 
    refetch
  } = useQuery(listMonitorVaultsQuery, {
    variables: { limit: PAGE_SIZE, nextToken: null },
    fetchPolicy: 'cache-and-network',
    skip: true, // Skip the automatic query execution
    notifyOnNetworkStatusChange: true
  });

  // Function to refresh all data from the beginning
  const refreshAll = useCallback(async () => {
    try {
      // Don't set loading to true for auto-refreshes to avoid UI flickering
      if (!isRefreshing) {
        setIsLoading(true);
      } else {
        console.log("Auto-refreshing monitor vaults data...");
      }
      
      // Use a local variable to accumulate results
      let accumulatedVaults: MonitorVaults[] = [];
      let currentToken = null;
      let hasMoreData = true;
      
      // Use a while loop instead of recursion
      while (hasMoreData) {
        const result = await refetch({ 
          limit: PAGE_SIZE, 
          nextToken: currentToken 
        });
        
        if (result.data && result.data.listMonitorVaults) {
          const newItems = result.data.listMonitorVaults.items;
          
          // Filter if needed
          const filteredItems = onlyNeedRebalance 
            ? newItems.filter((v: MonitorVaults) => v.needRebalance)
            : newItems;
          
          // Add items to our local accumulator
          accumulatedVaults = [...accumulatedVaults, ...filteredItems];
          
          // Check if there are more pages
          currentToken = result.data.listMonitorVaults.nextToken;
          hasMoreData = !!currentToken;
        } else {
          hasMoreData = false;
        }
      }
      
      // Set state once at the end with all data
      setAllVaults(accumulatedVaults);
      
    } catch (err) {
      console.error("Error in refreshAll for vaults:", err);
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  }, [refetch, isRefreshing, onlyNeedRebalance]);

  // Auto-refresh functionality
  useEffect(() => {
    // Clean up any existing interval
    if (refreshIntervalRef.current) {
      clearInterval(refreshIntervalRef.current);
    }
    
    // Set up auto-refresh interval
    refreshIntervalRef.current = setInterval(() => {
      setIsRefreshing(true);
      refreshAll();
    }, AUTO_REFRESH_INTERVAL);
    
    // Clean up interval on unmount
    return () => {
      if (refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
        refreshIntervalRef.current = null;
      }
    };
  }, [refreshAll]);

  // Effect to trigger the initial data load - runs only once
  useEffect(() => {
    refreshAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // When onlyNeedRebalance changes, we need to refresh
  useEffect(() => {
    refreshAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyNeedRebalance]);
  
  // Handle initial error
  useEffect(() => {
    if (initialError) {
      console.error("Error in initial vaults data load:", initialError);
      setError(initialError);
    }
  }, [initialError]);

  // Function to manually trigger refresh
  const manualRefresh = () => {
    setIsLoading(true);
    refreshAll();
  };

  return {
    vaults: allVaults,
    loading: isLoading || initialLoading,
    error,
    refreshAll: manualRefresh,
    isRefreshing
  };
};